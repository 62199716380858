import {ButtonStyled} from "../ButtonStyled/ButtonStyled"
import clsx from "clsx"
import styles from "./NavigationButtonsGroup.module.scss"
import React from "react"
import {NavigationButtonData} from "../types"
import {NavigationButtonsContainer} from "../NavigationButtonsContainer/NavigationButtonsContainer"

type Props = {
  buttonData: Array<NavigationButtonData>
  activeButton: number
  handleButtonClick: (id: number) => void
  variant: 'dropdown' | 'profile'
}

export const NavigationButtonsGroup = ({buttonData, activeButton, handleButtonClick, variant}: Props) => {
  return (
    <NavigationButtonsContainer variant={variant}>
      <div className={styles.buttonGroup} role="group" aria-label="Basic example">
        {buttonData.map((button) => (
          <ButtonStyled
            key={button.id}
            variant={activeButton === button.id ? 'primary' : 'glass'}
            onClick={() => handleButtonClick(button.id)}
            className={clsx(
              variant === 'dropdown' ? styles.buttonDropdown : styles.buttonProfile,
              styles.navigationButton
            )}
          >
            <div className={styles.buttonContent}>
              {button.label} <img src={button.img} alt={button.imgAlt}
                                  className={button.label.length > 0 ? styles.imgWithMargin : ''}/> {/* if button has label, then add margin to img */}
            </div>
          </ButtonStyled>
        ))}
      </div>
    </NavigationButtonsContainer>
  )
}