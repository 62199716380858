import React from 'react'
import styles from './PlayerContainer.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import {Avatar} from "../Icons/User01Icon/Avatar"
import CopyToClipboard from "react-copy-to-clipboard"
import copy_06 from "../../assets/images/icons/copy_06.svg"

type Props = {
  loginName: string
  playerId: string
}

export const PlayerContainer = ({loginName, playerId}: Props) => {
  return (
    <div className={styles.playerContainer}>
      <Avatar className={stylesCommon.userIcon}/>
      <div className={styles.playerData}>
        <div className={styles.name}>{loginName}</div>
        <div className={styles.playerContainer}>
          <div className={styles.player}>Player ID</div>
          <div className={styles.playerID}>{playerId}</div>
          <CopyToClipboard text={playerId ? playerId : ''}>
            <img src={copy_06} alt="copy_06" className={styles.copy}/>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  )
}