import styles from './Promo.module.scss'
import trophy_2_1_1 from 'src/assets/images/trophy_2_1_1.png'
import {PromoInput} from "./PromoInput/PromoInput"

export const Promo = () => {
  return (
    <div className={styles.promo}>
       <div className={styles.imgTexts}>
         <img src={trophy_2_1_1} alt="trophy_2_1_1"/>
         <div className={styles.textBlock}>
           <div className={styles.promoHeader}>Promo code</div>
           <div className={styles.promoDescription}>Get your promo code via personal message or social networks</div>
         </div>
       </div>
      <PromoInput />
    </div>
  )
}