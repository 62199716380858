import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from "../../../../hooks"
import {useLocation, useNavigate} from 'react-router-dom'
import axios from 'axios'
import TextInput from '../formComponents/TextInput'
import DataInput from '../formComponents/DataInput/DataInput'
import PasswordInput from '../formComponents/PasswordInput'
import SelectInput from '../formComponents/SelectInput/SelectInput'
import CheckboxInput from '../formComponents/CheckboxInput'
import Alert from '../../../layouts/ui/alert/Alert'
import config from '../../../../config'
import styles from './Signup.module.scss'
import stylesAuthCommon from "../AuthCommon.module.scss"
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import signup_background from 'src/assets/images/signup_background.jpg'
import Grid from '@mui/material/Grid2'
import bonus_chip from 'src/assets/images/bonus_chip.png'
import bonus_gift from 'src/assets/images/bonus_gift.png'

import {fetchAuthform} from 'src/redux/actions/construct/authformActions'

import {
  selectAuthform,
  selectAuthformError,
  selectAuthformErrorArray,
  selectAuthformLoading,
  selectAuthformMessages,
  selectAuthformSuccess
} from '../../../../redux/selectors/construct/authformSelectors'

import {actionCodeConfirmationClearState, fetchCodeConfirmation} from 'src/redux/actions/util/codeConfirmationActions'

import {
  selectCodeConfirmation,
  selectCodeConfirmationCreatedSuccess,
  selectCodeConfirmationError,
  selectCodeConfirmationErrorArray,
  selectCodeConfirmationErrorString,
  selectCodeConfirmationLoading,
  selectCodeConfirmationMessages
} from '../../../../redux/selectors/util/registrationAccauntSelectors'

import {
  actionSetRegistrationFormSettingsId,
  fetchGetRegistrationFormSettings
} from 'src/redux/actions/setting/getRegistrationFormSettingsActions'

import {
  selectGetRegistrationFormSettings,
  selectGetRegistrationFormSettingsError,
  selectGetRegistrationFormSettingsLoading,
  selectGetRegistrationFormSettingsMessages,
  selectRegistrationFormSettingsId,
  selectSettingRegistrationFormElements
} from '../../../../redux/selectors/setting/getRegistrationFormSettingsSelectors'

import {
  actionRegisterValidateFirstScreenClearError,
  fetchRegisterValidateFirstScreen
} from '../../../../redux/actions/site/registerValidationFirstScreenAction'
import {
  selectRegisterValidationFirstScreenErrors,
  selectRegisterValidationFirstScreenSuccess
} from '../../../../redux/selectors/site/registerValidationFirstScreenSelectors'

import {
  actionAccauntRegistrationClearState,
  fetchAccauntRegistration
} from '../../../../redux/actions/site/registrationActions'
import {
  selectRegistrationAccaunt,
  selectRegistrationAccauntCreatedSuccess,
  selectRegistrationAccauntError,
  selectRegistrationAccauntErrorArray,
  selectRegistrationAccauntLoading,
  selectRegistrationAccauntMessages
} from '../../../../redux/selectors/site/registrationAccauntSelectors'

import {selectIsAuthenticated, selectToken} from '../../../../redux/selectors/site/loginSelectors'
import {NavigationButtonsContainer} from "../../../../common/NavigationButtonsContainer/NavigationButtonsContainer"
import {LoginNavigationButtons} from "../formComponents/LoginNavigationButtons/LoginNavigationButtons"
import {SocialAuth} from "../formComponents/SocialAuth/SocialAuth"
import {SubmitButton} from "../formComponents/SubmitButton/SubmitButton"
import {BrightCheckbox} from "../../../../common/BrightCheckbox/BrightCheckbox"
import mail_01_passive from 'src/assets/images/icons/mail_01_passive.svg'
import mail_01 from 'src/assets/images/icons/mail_01.svg'
import {SelectChangeEvent} from '@mui/material'

// Интерфейсы для состояний формы и ошибок клиента
interface FormState {
  [key: string]: string | boolean;
}

interface ClientErrors {
  required: { [key: string]: string };
}

interface RegistrationFormElement {
  id: string;
  id_form: string;
  code: string;
  name: string;
  flag_displayed: string;
  flag_required: string;
  flag_displayed_readonly: string;
  flag_required_readonly: string;
  pos: string;
}

interface Country {
  country_id: string;
  title_ru: string;
  title_ua: string;
  title_be: string;
  title_en: string;
  title_es: string;
  title_pt: string;
  title_de: string;
  title_fr: string;
  title_it: string;
  title_pl: string;
  title_ja: string;
  title_lt: string;
  title_lv: string;
  title_cz: string;
  iso: string;
}

interface Bonus {
  id: string;
  name: string;
  image_path: string | null;
}

interface CheckedBonus {
  id: string | null;
}

interface Authform {
  bonuses: Bonus[];
}


interface SignupProps {
  handleButtonActiveTabClick: (tab: 'login' | 'signup') => void;
}

// type ValidationErrorKeys = 'email' | 'password' | 'currency';

const Signup: React.FC<SignupProps> = ({handleButtonActiveTabClick}) => {
  // const dispatch = useDispatch();
  const dispatch = useAppDispatch()

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)

  // Рефы
  const formRef = useRef<HTMLFormElement>(null)

  // Навигация
  const navigate = useNavigate()
  const location = useLocation()


  const authform = useSelector(selectAuthform)

  const authformSuccess = useSelector(selectAuthformSuccess)
  const authformMessages = useSelector(selectAuthformMessages)
  const authformLoading = useSelector(selectAuthformLoading)
  const authformError = useSelector(selectAuthformError)
  const authformErrorArray = useSelector(selectAuthformErrorArray)

  // Результаты запроса на генерацию кода подтверждения
  const codeConfirmation = useSelector(selectCodeConfirmation)
  const codeConfirmationCreatedSuccess = useSelector(selectCodeConfirmationCreatedSuccess)
  const codeConfirmationMessages = useSelector(selectCodeConfirmationMessages)
  const codeConfirmationLoading = useSelector(selectCodeConfirmationLoading)
  const codeConfirmationError = useSelector(selectCodeConfirmationError)
  const codeConfirmationErrorString = useSelector(selectCodeConfirmationErrorString)
  const codeConfirmationErrorArray = useSelector(selectCodeConfirmationErrorArray)

  // Результаты запроса на получение настройки формы
  const getRegistrationFormSettings = useSelector(selectGetRegistrationFormSettings)
  const getRegistrationFormSettingsLoading = useSelector(selectGetRegistrationFormSettingsLoading)
  const getRegistrationFormSettingsError = useSelector(selectGetRegistrationFormSettingsError)
  const getRegistrationFormSettingsMessages = useSelector(selectGetRegistrationFormSettingsMessages)
  const registrationFormSettingsId = useSelector(selectRegistrationFormSettingsId)


  // Валидация формы первого экрана
  const registerValidationFirstScreenSuccess = useSelector(selectRegisterValidationFirstScreenSuccess)
  const registerValidationFirstScreenError = useSelector(selectRegisterValidationFirstScreenErrors)
  // const registerValidationFirstScreenLoading = useSelector(selectRegisterValidationFirstScreenLoading);

  // Результаты запроса на регистрацию игрока
  const registrationAccauntData = useSelector(selectRegistrationAccaunt)
  const registrationAccauntLoading = useSelector(selectRegistrationAccauntLoading)
  const registrationAccauntError = useSelector(selectRegistrationAccauntError)
  const registrationAccauntErrorArray = useSelector(selectRegistrationAccauntErrorArray)
  const registrationAccauntMessages = useSelector(selectRegistrationAccauntMessages)
  const registrationAccauntCreatedSuccess = useSelector(selectRegistrationAccauntCreatedSuccess)
  const settingRegistrationFormElements = useSelector(selectSettingRegistrationFormElements)

  // Вспомогательные локальные стэйты
  const [debouncedId, setDebouncedId] = useState<string | null>(registrationFormSettingsId)
  const [formState, setFormState] = useState<FormState>({})
  const [initialState, setInitialState] = useState<FormState>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState<boolean>(false)
  const [clientErrors, setClientErrors] = useState<ClientErrors>({required: {}})
  const [ip, setIp] = useState<string>('')
  const [view, setView] = useState<'stepOne' | 'stepTwo'>('stepOne')
  const [checkedBonus, setCheckedBonus] = useState<string | null>(null) // хранит id бонуса или null
  const [showErrors, setShowErrors] = useState(false) //это чтобы алерт с ошибками не появлялся после закрытия
  const [showConfirmationErrors, setShowConfirmationErrors] = useState(false) // это только ошибки конфирма
  const [firstScreenServerValidationErrors, setFirstScreenServerValidationErrors] = useState({
    email: null,
    password: null,
    currency: null
  })
  // const [firstScreenServerValidationErrors, setFirstScreenServerValidationErrors] = useState<Record<ValidationErrorKeys, string | null>>({
  //     email: null,
  //     password: null,
  //     currency: null,
  // });
  const [secondScreenServerValidationErrors, setSecondScreenServerValidationErrors] = useState<Record<string, string>>({})


  // Сервер при регистрации ожидает такие имена, а для вівода формі приходят другие
  // Поэтому требуется перед отправкой на сервер поменять название
  const prepereParamNameToServer = (name: string) => {
    switch (name) {
      case 'country':
        return 'id_country'
      case 'currency':
        return 'id_currency'
      case 'birth':
        return 'datebirth'
      case 'password':
        return 'new_password'
      // Добавьте другие преобразования по мере необходимости
      default:
        return name // Если нет необходимости в изменении, возвращаем имя как есть
    }
  }

  // Первоначальная инициализация формы
  useEffect(() => {
    dispatch(actionSetRegistrationFormSettingsId('1'))
    dispatch(fetchAuthform())
    console.log('component useEffect 1')
  }, [dispatch])

  // Задержка для обновления ID формы
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedId(registrationFormSettingsId)
    }, 300)

    return () => {
      clearTimeout(handler)
    }
  }, [registrationFormSettingsId])

  // Загрузка настроек формы при смене ID
  useEffect(() => {
    if (isAuthenticated && token) {
      navigate('/')
    } else if (debouncedId) {
      console.log('component registrationFormSettingsId:', debouncedId)
      dispatch(fetchGetRegistrationFormSettings(debouncedId))
    }
  }, [dispatch, isAuthenticated, token, debouncedId, navigate])


  // Данные формы при инициализации.
  useEffect(() => {
    const initialFormValues: FormState = {}

    if (getRegistrationFormSettingsError || getRegistrationFormSettings.length === 0) {
      return
    }

    getRegistrationFormSettings?.settingRegistrationFormElements.forEach((item: RegistrationFormElement) => {
      if (item.flag_displayed !== "YES") return
      if (item.code === 'currency') {
        const defaultCurrencyId = getRegistrationFormSettings.defaultCurrency.id
        initialFormValues['currency'] = defaultCurrencyId
      } else {
        initialFormValues[item.code] = ""
      }
    })

    console.log('getRegistrationFormSettings.defaultCurrency.id', getRegistrationFormSettings.defaultCurrency.id)
    console.log('initialFormValues', initialFormValues)


    setFormState(initialFormValues)
    setInitialState(initialFormValues)
  }, [getRegistrationFormSettings, getRegistrationFormSettingsError])

  // useEffect(() => {
  //     console.log('Signup formState', formState)
  //     console.log('Signup getRegistrationFormSettings', getRegistrationFormSettings)
  //     console.log('Signup authform Success', authformSuccess)
  //     console.log('Signup authform', authform)
  //     console.log('Signup authform checkedBonus', checkedBonus)
  // }, [getRegistrationFormSettings, formState, authformSuccess, authform, checkedBonus]);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json')
        setIp(response.data.ip)
      } catch (error) {
        console.error('Error fetching IP:', error)
      }
    }

    fetchIp()
  }, [])

  // After request server validation first screen response
  // Результат запроса на валидацию полей первого экрана
  useEffect(() => {
    console.log('registerValidationFirstScreenError', registerValidationFirstScreenError)
    if (registerValidationFirstScreenError) {

      let errors = {
        email: null,
        password: null,
        currency: null
      }

      if (registerValidationFirstScreenError.email) {
        errors.email = registerValidationFirstScreenError.email
      }

      if (registerValidationFirstScreenError.new_password) {
        errors.password = registerValidationFirstScreenError.new_password
      }

      if (registerValidationFirstScreenError.id_currency) {
        errors.password = registerValidationFirstScreenError.id_currency
      }

      setFirstScreenServerValidationErrors(errors)
      console.log('setFirstScreenServerValidationErrors', errors)


    }
    if (registerValidationFirstScreenSuccess) { // Если поля на сервере валидны, генерируем конфирм код
      if (clientValidation()) {
        const confirmationData = {
          email: formState.email,
          type: 'auth'
        }
        dispatch(fetchCodeConfirmation(confirmationData))
      }
    }
  }, [registerValidationFirstScreenSuccess, registerValidationFirstScreenError])

  // Хелпер для очистки ошибок первого шага
  const clearErrorsStepOne = () => {
    dispatch(actionRegisterValidateFirstScreenClearError())
    setFirstScreenServerValidationErrors({
      email: null,
      password: null,
      currency: null,
    })
  }

  // Очистка ошибок валидации первого экрана
  useEffect(() => {
    // Очистка ошибок при каждом монтировании компонента
    clearErrorsStepOne()

    // Очистка ошибок при размонтировании
    return () => {
      clearErrorsStepOne()
    }
  }, [])

  // useEffect(() => {
  //     console.log('firstScreenServerValidationErrors', firstScreenServerValidationErrors)
  // }, [firstScreenServerValidationErrors]);

  // Generate confirmation code response
  // Результат генерации конфирм кода
  useEffect(() => {

    if (codeConfirmationError) {
      setShowConfirmationErrors(true) // раз ошибки есть то разблокируем показ окна алерта
    } else if (codeConfirmationCreatedSuccess) {
      setView('stepTwo') // if code sent ok go to step to screen
      clearErrorsStepOne() // очистим ошибки первого шага
    }
  }, [codeConfirmationCreatedSuccess, codeConfirmationError])

  //
  useEffect(() => {

    if (codeConfirmationCreatedSuccess && view === 'stepTwo') {
      dispatch(actionCodeConfirmationClearState())
    }

  }, [codeConfirmationCreatedSuccess, codeConfirmationError, view])

  // Результат регистрации на втором экране
  useEffect(() => {
    if (registrationAccauntCreatedSuccess) {
      setIsRegistrationSuccess(true)
      setFormState(initialState) // Очистка формы после успешной регистрации
    }
    if (registrationAccauntError) {
      setShowErrors(true) // раз ошибки есть то разблокируем показ окна алерта

      console.log('registrationAccauntError', registrationAccauntError)

      // if(view === 'stepTwo'){
      //     const updateServerValidationErrors = (key: string, value: string) => { setSecondScreenServerValidationErrors(prevState => ({ ...prevState, [key]: value })); };


      //     let key = item.code;
      //     let value = null;
      //     if(registrationAccauntError[item.code]){
      //         value = registrationAccauntError[item.code];
      //     }
      //     updateServerValidationErrors(key, value);
      //     setSecondScreenServerValidationErrors({key: value});
      //     serverValidationErrors = secondScreenServerValidationErrors[item.code];
      // }


    }
  }, [registrationAccauntCreatedSuccess, registrationAccauntError, initialState])

  const clearDataAndErrorsStepTwo = () => {
    dispatch(actionAccauntRegistrationClearState())
    setIsRegistrationSuccess(false)
    setShowErrors(false)
  }

  // Очищаем данные регистрации после регистрации
  useEffect(() => {
    clearDataAndErrorsStepTwo()

    // Очистка ошибок при размонтировании
    return () => {
      clearDataAndErrorsStepTwo()
    }
  }, [])

  const prepareFormStateForServer = (formState: Record<string, any>) => {
    const preparedFormState: Record<string, any> = {} // Новый объект для преобразованных данных

    // Проходим по каждому элементу formState
    Object.entries(formState).forEach(([key, value]) => {
      const preparedName = prepereParamNameToServer(key) // Переименовываем ключ, если нужно
      preparedFormState[preparedName] = value // Добавляем преобразованный ключ и его значение в новый объект
    })

    return preparedFormState
  }

  // Обработка клика на кнопку Continue на первом экране
  const handleButtonContinueClick = () => {
    if (clientValidation()) {

      // const prepareFormStateForServer = (formState: Record<string, any>) => {
      //     const preparedFormState: Record<string, any> = {}; // Новый объект для преобразованных данных

      //     // Проходим по каждому элементу formState
      //     Object.entries(formState).forEach(([key, value]) => {
      //         const preparedName = prepereParamNameToServer(key); // Переименовываем ключ, если нужно
      //         preparedFormState[preparedName] = value; // Добавляем преобразованный ключ и его значение в новый объект
      //     });

      //     return preparedFormState;
      // };

      const preparedFormState = prepareFormStateForServer(formState)

      dispatch(fetchRegisterValidateFirstScreen(preparedFormState))
    } else {
      console.error('Validation failed')
    }
  }
  // Обработка формы первого экрана
  const handleConfirmationSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

  }

  // Обработка нажатия кнопки регистрации
  const handleButtonCreateAccauntClick = () => {
    clientValidation()
    console.log('handleButtonCreateAccauntClick')
    console.log('handleButtonCreateAccauntClick formState:', formState)
  }

  // Обработка отправки формы на регистрацию на втором экране
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsSubmitted(true)

    const updatedFormState = {
      ...formState,
      ip: ip,  // Добавляем IP пользователя в состояние формы
      bonus_id: checkedBonus !== null ? checkedBonus : undefined  // Добавляем ID бонуса что выбрали, если не нулл
    }


    console.log('handleSubmit data:', updatedFormState)

    if (clientValidation()) {  // Проверка валидации формы перед отправкой

      // const prepareFormStateForServer = (formState: Record<string, any>) => {
      //     const preparedFormState: Record<string, any> = {}; // Новый объект для преобразованных данных

      //     // Проходим по каждому элементу formState
      //     Object.entries(formState).forEach(([key, value]) => {
      //         const preparedName = prepereParamNameToServer(key); // Переименовываем ключ, если нужно
      //         preparedFormState[preparedName] = value; // Добавляем преобразованный ключ и его значение в новый объект
      //     });

      //     return preparedFormState;
      // };

      const preparedFormState = prepareFormStateForServer(updatedFormState)

      dispatch(fetchAccauntRegistration(preparedFormState))
    } else {
      console.error('Validation failed')
    }
  }

  // const handleButtonNextStepClick = () => {
  //     if (codeConfirmationCreatedSuccess) {
  //         setView('stepTwo')
  //     }
  // };


  // Функция валидации полей формы
  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    const newErrors: ClientErrors = {required: {}}
    let formIsValid = true

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        // Определяем, является ли поле пустым
        const isFieldEmpty =
          input.type === 'checkbox'
            ? !input.checked
            : typeof input.value === 'string' && input.value.trim() === '';

        if (input.required && isFieldEmpty) {
          formIsValid = false
          const errorMessage = input.getAttribute('data-error-required') || 'This field is required.'
          newErrors.required[input.name] = errorMessage
        }
      })
    }

    setClientErrors(newErrors)
    return formIsValid
  }

  const handleInputChange = (
    code: string, e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | SelectChangeEvent<string>
  ) => {

    // Используем `checked` для чекбоксов, а `value` для остальных полей
    const target = e.target as (HTMLInputElement | HTMLSelectElement);

    const value: string | boolean =
      target && target.type === 'checkbox' && 'checked' in target
        ? (target as HTMLInputElement).checked
        : String(target.value);

    console.log(code, value)
    const updatedFormState = {
      ...formState,
      [code]: value,  // Используем `code` как ключ, чтобы обновить соответствующее поле
    }
    // console.log(updatedFormState);
    setFormState(updatedFormState)

    // Проверяем, изменились ли данные по сравнению с начальным состоянием
    setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    // Если поле заполнено (или отмечено), удаляем его из ошибок валидации
    setClientErrors((prevErrors) => {
      const updatedErrors = {...prevErrors}

      // Проверка для строки перед использованием `trim()`
      const target = e.target as HTMLInputElement | HTMLSelectElement; // Type casting
      const isFieldFilled =
        target.type === 'checkbox'
          ? value
          : typeof value === 'string' && value.trim() !== '';

      if (isFieldFilled) {
        delete updatedErrors['required'][code]
      } else {
        const target = e.target as HTMLInputElement | HTMLSelectElement; // Type casting
        const errorMessage = target.getAttribute('data-error-required') || 'This field is required.';
        updatedErrors['required'][code] = errorMessage
      }

      return updatedErrors
    })
  }

  const handleErrorClose = () => {
    setShowErrors(false) // Скрываем ошибки при нажатии на крестик
  }

  // Закрытие алерта
  const handleConfirmationErrorClose = () => {
    setShowConfirmationErrors(false) // Скрываем ошибки при нажатии на крестик
  }

  // Кнопка закрытия на первом экране
  const handleClickCloseButtonInFirstScreen = () => {
    if (location.key !== 'default') {
      navigate(-1) // Переход на предыдущую страницу, если история есть
    } else {
      navigate('/') // Переход на главную, если истории нет
    }
  }


  useEffect(() => {
    console.log('registerValidationFirstScreenError', registerValidationFirstScreenError)
    if (registerValidationFirstScreenError) {

      let errors = {
        email: null,
        password: null,
        currency: null
      }

      if (registerValidationFirstScreenError.email) {
        errors.email = registerValidationFirstScreenError.email
      }

      if (registerValidationFirstScreenError.new_password) {
        errors.password = registerValidationFirstScreenError.new_password
      }

      if (registerValidationFirstScreenError.id_currency) {
        errors.password = registerValidationFirstScreenError.id_currency
      }

      setFirstScreenServerValidationErrors(errors)
      console.log('setFirstScreenServerValidationErrors', errors)


    }
    if (registerValidationFirstScreenSuccess) { // Если поля на сервере валидны, генерируем конфирм код
      if (clientValidation()) {
        const confirmationData = {
          email: formState.email,
          type: 'auth'
        }
        dispatch(fetchCodeConfirmation(confirmationData))
      }
    }
  }, [registerValidationFirstScreenSuccess, registerValidationFirstScreenError])

  // Рендеринг полей формы в зависимости от типа
  const renderFormField = (item: RegistrationFormElement) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement> | SelectChangeEvent<string>) =>
      handleInputChange(item.code, e)

    let serverValidationErrors = null
    if (view === 'stepOne') {
      if (item.code === "email" || item.code === "password" || item.code === "currency") {
        serverValidationErrors = firstScreenServerValidationErrors[item.code]
      }
    } else if (view === 'stepTwo') { // добавляем валидацию в поля на втором экране
      if (registrationAccauntError?.[item.code]) {
        serverValidationErrors = registrationAccauntError[item.code]
      }
    }

    const commonProps = {
      id: item.code,
      name: item.code,
      value: String(formState[item.code] || ''),
      onChange: handleChange, // Обработчик для input и select
      required: item.flag_required === "YES",
      placeholder: item.name,
      error: clientErrors.required[item.code] || serverValidationErrors
    }

    switch (item.code) {
      case 'currency':
        console.log('Signup formState /renderFormField /currency', formState)
        const currencyOptions = getRegistrationFormSettings.defaultCurrency
        return <SelectInput {...commonProps} options={[{id: currencyOptions.id, name: currencyOptions.code}]} variant={'currency'}/>

      case 'country':
        const countries: Country[] = getRegistrationFormSettings.countries

        return (
          <>
            <SelectInput {...commonProps} options={countries.map((country: Country) => ({
              id: String(country.country_id),
              name: country.title_en
            }))}
            variant={'country'}
            />
          </>

        )

      case 'terms':
        return (
          <CheckboxInput
            {...commonProps}
            checked={!!formState[item.code]} // Булевое значение для контролируемого чекбокса
            hint="I agree to the Terms & Conditions and Privacy Policy"
          />
        )

      case 'birth':
        return <DataInput {...commonProps} />

      case 'password':
        return (
          <>
            <PasswordInput {...commonProps} id="new_password" name="new_password"
                           placeholder="Enter new password"/>
            {(
              getRegistrationFormSettings?.is_password_confirmation_required
              &&
              <PasswordInput {...commonProps} id="confirm_password" name="confirm_password"
                             placeholder="Confirm password"/>
            )}

          </>
        )

      case 'email':
        return (
          <TextInput {...commonProps}
                     activeIcon={mail_01}
                     passiveIcon={mail_01_passive}
          />
        )

      default:
        return <TextInput {...commonProps} />
    }
  }

  // Левый блок - 40% ширины
  // checkedBonus
  const defaultBackgroundColor = '#b2b2b2'

  const renderLeftBar = (): JSX.Element => {
    if (authformSuccess && authform) {
      const imgUrl = config.fileServerBaseUrl
      let backgroundImage = ''
      let title = authform.title

      // Если выбран бонус и у него есть изображение
      let selectedBonus: Bonus | undefined
      if (checkedBonus !== null) {
        selectedBonus = authform.bonuses.find((bonus: Bonus) => bonus.id === checkedBonus)
        if (selectedBonus && selectedBonus.image_path) {
          backgroundImage = `${imgUrl}${selectedBonus.image_path}`
        } else {
          // если у бонуса нет картинки
          backgroundImage = ''
        }
        if (selectedBonus && selectedBonus.name) {
          title = selectedBonus.name
        }

        console.log('selectedBonus', selectedBonus)
      }

      // Определение фонового изображения в зависимости от разрешения экрана (если бонус не выбран)
      if (!backgroundImage) {
        backgroundImage = `${imgUrl}${authform.image_path_desktop}`
        if (window.matchMedia("(min-width: 1200px)").matches) {
          backgroundImage = `${imgUrl}${authform.image_path_large_desktop}`
        } else if (window.matchMedia("(min-width: 768px) and (max-width: 1199px)").matches) {
          backgroundImage = `${imgUrl}${authform.image_path_tablet}`
        } else if (window.matchMedia("(max-width: 767px)").matches) {
          backgroundImage = `${imgUrl}${authform.image_path_mobile}`
        }
      }

      return (
        <Grid size={1}
        >
          <div className={stylesAuthCommon.leftContent}
               style={{
                 "--background": `url(${signup_background})`,
               } as React.CSSProperties}>


            {
              selectedBonus &&
                <div className={styles.extraBonusTitle}>Welcome Bonus Offer</div>

            }
            <div className={stylesAuthCommon.text}>{title}</div>

          </div>
        </Grid>
      )
    } else {
      return (
        <Grid size={1}
              style={{
                backgroundColor: defaultBackgroundColor,
                backgroundSize: 'cover',
                borderRadius: '0.5rem'
              }}
        >
          <div className={stylesAuthCommon.leftContent}>
            <h2 className="text-white">Welcome!</h2>
            <p className="text-white">
              Quick registration and login. Join us!
            </p>
          </div>
        </Grid>
      )
    }
  }

  const renderBonusButtons = (): JSX.Element => {
    if (authformSuccess && authform) {
      const imgUrl = config.fileServerBaseUrl

      return (
        <>
          {authform.bonuses.map((bonus: Bonus, index: number) => (
            <button
              key={bonus.id}
              onClick={() => {
                setCheckedBonus((prev) => prev === bonus.id ? null : bonus.id) // установка состояния
              }}
              className={`${stylesAuthCommon.btnInBlock} ${checkedBonus === bonus.id ? stylesAuthCommon.active : ''}`}
            >
              {
                ((index + 1) % 2 === 0)
                  ? <img src={bonus_gift} alt={'bonus'}/>
                  : <img src={bonus_chip} alt={'bonus'}/>
              }
            </button>
          ))}
        </>
      )
    } else {
      return (<></>)
    }
  }


  type SubscriptionBlockProps = {
    subscription: boolean
    handleInputChange: (field: string, event: React.ChangeEvent<HTMLInputElement>) => void
  };

  const SubscriptionBlock = ({handleInputChange, subscription}: SubscriptionBlockProps) => {
      return(
        <div key="subscription-checkbox" className={styles.subscriptionBlock}>
          {getRegistrationFormSettings?.flag_subscription !== 'YES' && (
            <div>
              <div className="form-check">
                <BrightCheckbox
                  className="form-check-input"
                  id="subscriptionCheck"
                  name="subscription"
                  checked={subscription}
                  onChange={(e) => handleInputChange('subscription', e)}
                />
                <label htmlFor="subscriptionCheck" className={stylesCommon.checkboxHint}>
                  I want to receive news and updates
                </label>
              </div>
            </div>
          )}
        </div>
      )
  }

  // Render View 1
  const renderViewStepOne = (): JSX.Element => (
    <>
      <div className={styles.outsideButtonsBlock} id="button-close-registration">
        {renderBonusButtons()}

      </div>
      <div className={styles.signup}>
        <Grid container columns={3}>
          {/* Left block - 1/3 width */}
          {renderLeftBar()}

          {/* Right block - 2/3 width */}
          <Grid size={2}>
            <div className={stylesAuthCommon.rightContent}>

              {/* Блок с кнопками Логин и Регистрация */}
              <NavigationButtonsContainer>
                <LoginNavigationButtons
                  onLoginClick={() => handleButtonActiveTabClick('login')}
                  onRegisterClick={() => handleButtonActiveTabClick('signup')}
                  activeButton={'register'}
                />
              </NavigationButtonsContainer>



              {/* Блок с формой */}
              <form onSubmit={handleConfirmationSubmit} ref={formRef}>
                <div className={styles.signupFormInputs}>
                  {settingRegistrationFormElements && settingRegistrationFormElements.map((item: RegistrationFormElement, index: number) => {
                    if (item.flag_displayed !== "YES") return null

                    if (item.code === "email" || item.code === "password" || item.code === "currency" || item.code === "terms") {
                      return (
                        <div key={item.id || index} className={

                          (item.code === "email" || item.code === "password")
                            ? styles.smallMarginBottom
                            : styles.bigMarginBottom
                        }>
                          {/*<React.Fragment>*/}
                          { item.code === "terms" && <SubscriptionBlock subscription={!!formState.subscription} handleInputChange={handleInputChange}/> }  {/*render subscription-checkbox before terms*/}
                          {renderFormField(item)}
                          {/*</React.Fragment>*/}
                        </div>
                      )
                    }
                  })}
                </div>

                <div key="gender-radio">
                  {getRegistrationFormSettings.flag_gender === "YES" && (
                    <div className="mb-3">
                      <label className="form-label"><strong>Gender</strong></label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="genderMale"
                          value="male"
                        />
                        <label className="form-check-label" htmlFor="genderMale">
                          Male
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="genderFemale"
                          value="female"
                        />
                        <label className="form-check-label" htmlFor="genderFemale">
                          Female
                        </label>
                      </div>
                    </div>
                  )}
                </div>

                {/* Блок с кнопкой и ссылкой */}
                <SubmitButton onClick={handleButtonContinueClick} isLoading={codeConfirmationLoading}
                              text={'Continue'}/>
              </form>

              {showConfirmationErrors && codeConfirmationErrorArray && codeConfirmationErrorArray.length > 0 && (
                <Alert onClose={handleConfirmationErrorClose} type="error">
                  {codeConfirmationErrorArray.map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </Alert>
              )}


              {codeConfirmationCreatedSuccess && (
                <Alert><strong>{codeConfirmationMessages ? codeConfirmationMessages : 'Success!'}</strong></Alert>
              )}

              {/* Блок с соцсетями */}
              <SocialAuth variant={'sign up'}/>

            </div>
          </Grid>
        </Grid>
        {/* тут будет внутренний контент */}
      </div>
    </>
  )

  type RegistrationFormElement = {
    id: number
    id_form: number
    code: string
    name: string
    flag_displayed: 'YES' | 'NO'
    flag_displayed_readonly: 'YES' | 'NO'
    flag_required: 'YES' | 'NO'
    flag_required_readonly: 'YES' | 'NO'
    pos: number
  };

  // Render View 2
  const renderViewStepTwo = (): JSX.Element => {

    const updatedElements = settingRegistrationFormElements
      .map((item: RegistrationFormElement) => {
        if (item.code === 'verifycode') {
          return {...item, flag_displayed: 'NO'} // Modify the flag_displayed for 'verifycode'
        }
        return item
      })
    //  reposition 'city' before 'birth':
    const cityIndex = updatedElements.findIndex((item: RegistrationFormElement) => item.code === 'city')
    const birthIndex = updatedElements.findIndex((item: RegistrationFormElement) => item.code === 'birth')
    // If 'city' comes after 'birth', reposition 'city'
    if (cityIndex > birthIndex) {
      // Remove the 'city' element from its current position
      const [cityElement] = updatedElements.splice(cityIndex, 1)
      // Insert the 'city' element before 'birth'
      updatedElements.splice(birthIndex, 0, cityElement)
    }

    return <div className={styles.signup}>
      <Grid container columns={3}>
        {/* Left block - 1/3 width */}
        {renderLeftBar()}

        {/* Right block - 2/3 width */}
        <Grid size={2}>
          <div className={stylesAuthCommon.rightContent}>

            {/* Блок с кнопками Логин и Регистрация */}
            <NavigationButtonsContainer>
              <LoginNavigationButtons
                onLoginClick={() => handleButtonActiveTabClick('login')}
                onRegisterClick={() => handleButtonActiveTabClick('signup')}
                activeButton={'register'}
              />
            </NavigationButtonsContainer>

            {/* Блок с формой */}
            <form onSubmit={handleSubmit} ref={formRef} className={styles.signupFormInputs}>
              <Grid container columns={2} columnSpacing={'10px'} rowSpacing={'16px'}>
                {updatedElements && updatedElements.map((item: RegistrationFormElement, index: number) => {
                  if (item.flag_displayed !== "YES") return null

                  if (item.code === "email" || item.code === "password" || item.code === "currency" || item.code === "terms"
                  ) {
                    return null
                  }

                  return (
                    <Grid size={item.code === 'birth' ? 2 : 1} key={item.id || index}>
                      <React.Fragment>
                        {renderFormField(item)}
                      </React.Fragment>
                    </Grid>
                  )
                })}

              </Grid>

              <div key="gender-radio">
                {getRegistrationFormSettings.flag_gender === "YES" && (
                  <div className="mb-3">
                    <label className="form-label"><strong>Gender</strong></label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="genderMale"
                        value="male"
                      />
                      <label className="form-check-label" htmlFor="genderMale">
                        Male
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="genderFemale"
                        value="female"
                      />
                      <label className="form-check-label" htmlFor="genderFemale">
                        Female
                      </label>
                    </div>
                  </div>
                )}
              </div>

              {/* Блок с кнопкой и ссылкой */}
              <SubmitButton onClick={handleButtonCreateAccauntClick} isLoading={registrationAccauntLoading}
                            text={'Register'}/>
            </form>

            {showErrors && registrationAccauntErrorArray && registrationAccauntErrorArray.length > 0 && (
              <Alert onClose={handleErrorClose} type="error">
                {registrationAccauntErrorArray.map((error, index) => {
                  return (<p key={index}>{error}</p>)
                  // Проверяем, существует ли ошибка в локальном состоянии
                  // if (!secondScreenServerValidationErrors.hasOwnProperty(index)) {
                  //     return (<p key={index}>{error}</p>);
                  // }
                  // return null; // Не выводим ошибку, если она есть в локальном состоянии
                })}
              </Alert>
            )}


            {isRegistrationSuccess && (
              <Alert><strong>Success!</strong> Player account has been created! Now you can log
                in!</Alert>
            )}


            {/* Блок с соцсетями */}
            <SocialAuth variant={'sign up'}/>

          </div>
        </Grid>
      </Grid>
      {/* тут будет внутренний контент */}
    </div>
  }

  // -------------------------------
  // Вывод представлений
  // -------------------------------

  // Рендеринг представления страницы
  if (getRegistrationFormSettingsLoading) {
    return <p>loading...</p>
  }

  if (getRegistrationFormSettingsError) {
    return (
      <div className="alert alert-danger mt-2" role="alert">
        {getRegistrationFormSettingsError || 'An unexpected error occurred.'}
      </div>
    )
  }

  if (!getRegistrationFormSettings || getRegistrationFormSettings.length === 0) {
    return <p>no data...</p>
  }

  if (getRegistrationFormSettings?.flag_active !== 'YES') {
    return <p>This form is inactive...</p>
  }

  switch (view) {
    case 'stepOne':
      return (renderViewStepOne())
    case 'stepTwo':
      return (renderViewStepTwo())

    default:
      return null // Можно вернуть null или какой-то компонент по умолчанию
  }

}

export default Signup
