import React from 'react'
import styles from './ProfileDropdown.module.scss'
import stylesCommon from 'src/common/styles/commonStyles.module.scss'
import clsx from 'clsx'
import {Avatar} from "../../../common/Icons/User01Icon/Avatar"
import Tippy_up from "src/assets/images/icons/Tippy_up.svg"
import {useSelector} from "react-redux"
import {useGetPersonalInfoQuery} from "../../../api/api"
import {selectIsAuthenticated, selectToken} from "../../../redux/selectors/site/loginSelectors"
import {Link, useNavigate} from "react-router-dom"
import {clearChildrenMenuData} from "../../../redux/slices/interfaceSlice"
import {setShowModalMyProgress} from "src/redux/actions/player/myProgressActions"
import {fetchLogout} from "src/redux/actions/site/logoutActions"
import {useAppDispatch} from "../../../hooks"
import {PlayerContainer} from "../../../common/PlayerContainer/PlayerContainer"


type Props = {
  showDropdown: boolean
  setShowDropdown: (value: boolean) => void
}


export const ProfileDropdown = ({
                                  showDropdown,
                                  setShowDropdown,
                                }: Props) => {


  const token = useSelector(selectToken)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isAuthenticated = useSelector(selectIsAuthenticated)


  const {data: getPersonalInfoData} = useGetPersonalInfoQuery({token: token ? token : ''})

  const playerId = getPersonalInfoData?.data?.player_id


  const handleLogout = async () => {
    dispatch(clearChildrenMenuData())
    dispatch(setShowModalMyProgress(false))
    await dispatch(fetchLogout(token as string))
    if (!isAuthenticated) {
      navigate('/auth')
    }
  }

  type ProfileDropdownLink = {
    to?: string
    name: string
    onClick?: () => void
  }

  const ProfileDropdownLink = ({to, name, onClick}: ProfileDropdownLink) => {
    const handleClick = () => {
      if (onClick) {
        onClick()
      }
      setShowDropdown(false)
    }

    if (to) {
      return (
        <Link to={to} className={styles.link} onClick={handleClick}>
          {name}
        </Link>
      )
    }

    return (
      <div className={styles.link} onClick={handleClick}>
        {name}
      </div>
    )
  }

  return (
    <div className={styles.component}>
      {showDropdown && <img src={Tippy_up} alt="Tippy_up" className={styles.tippy}/>}
      <Avatar className={stylesCommon.userIcon} style={{cursor: 'pointer'}} onClick={() => setShowDropdown(!showDropdown)}/>
      <div
        className={clsx(
          "dropdown-menu" + (showDropdown ? " show" : ""),
          styles.menu
        )}
      >
        {getPersonalInfoData?.data?.login && playerId && <PlayerContainer loginName={getPersonalInfoData?.data?.login} playerId={playerId} /> }
        <div className={styles.linksContainer}>
          <ProfileDropdownLink to={'/personal-information'} name={'My profile'}/>
          <ProfileDropdownLink to={'/accounts'} name={'My wallets'}/>
          <ProfileDropdownLink to={'/transactions'} name={'History'}/>
          <ProfileDropdownLink onClick={handleLogout} name={'Logout'}/>
        </div>
      </div>
    </div>
  )
}
