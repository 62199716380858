import React from 'react'
import styles from './LoginNavigationButtons.module.scss' // Импорты стилей
import {ButtonStyled} from "src/common/ButtonStyled/ButtonStyled"

// Пропсы для компонента
type LoginNavigationButtonsProps = {
  onLoginClick: () => void
  onRegisterClick: () => void
  activeButton: 'login' | 'register'
}

export const LoginNavigationButtons: React.FC<LoginNavigationButtonsProps> = ({ onLoginClick, onRegisterClick, activeButton }) => {
  return (
    <div className={styles.loginNavigationButtonsGroup}>
      <ButtonStyled onClick={onLoginClick} className={styles.navigationButton} variant={activeButton === 'login' ? 'primary' : 'glass'} >
        Login
      </ButtonStyled>
      <ButtonStyled
        onClick={onRegisterClick}
        variant={activeButton === 'register' ? 'primary' : 'glass'}
        className={styles.navigationButton}
      >
        Register
      </ButtonStyled>
    </div>
  )
}