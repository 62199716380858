import styles from "./InputWithIcon.module.scss"
import clsx from "clsx"
import React, {forwardRef, useRef, useState} from "react"
import eye from 'src/assets/images/icons/eye.svg'
import eye_off from 'src/assets/images/icons/eye_off.svg'
import eye_passive from 'src/assets/images/icons/eye_passive.svg'

type PropsType = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  onKeyUp?: () => void
  variation?: 'search' | 'auth'
  icon?: string
  activeIcon?: string
  iconPosition?: 'left' | 'right';
}

export const InputWithIcon = forwardRef<HTMLDivElement, PropsType>( ({
                                                     className,
                                                     onKeyUp,
                                                     variation = 'search',
                                                     icon,
                                                     activeIcon = icon,
                                                     iconPosition = 'left',
                                                     ...rest
                                                   }, ref) => {

  const inputRef = useRef<HTMLInputElement>(null);
  const [isPasswordVisible, setPasswordVisibility] = useState(false)
  const [hasText, setHasText] = useState(false); // Tracks if input has text

  const handleInput = () => { /* for autofill icon */
    if (inputRef.current) {
      setHasText(!!inputRef.current.value.trim()); // Check if input has text
    }
  };

  const showPassword = () => {
    if (!hasText) return
    setPasswordVisibility(true)
  }

  const hidePassword = () => {
    if (!hasText) return
    setPasswordVisibility(false)
  }

    return (
      <div ref={ref} className={clsx(styles.search,
        className,
        iconPosition === 'right' && styles.iconRight
      ) } style={{
        "--icon": `url(${hasText  ? activeIcon : icon})`, // Pass the icon through a CSS variable
      } as React.CSSProperties}>
        <input
          className={clsx(styles.input,
                    icon && styles.inputWithIcon,
                    variation === 'auth' ? styles.inputAuth : styles.inputSearch,
                    iconPosition === 'right' && styles.inputWithIconRight
          )}
          onInput={handleInput}
          onKeyUp={onKeyUp}
          ref={inputRef}
          {...rest}
          type={
            rest.type === 'password'
              ? (isPasswordVisible ? 'text' : 'password')
              : 'text'
          }
        />
        {rest.type === 'password' && <img src={hasText ? (isPasswordVisible ? eye_off : eye) : eye_passive} alt="" className={clsx(styles.eye, !hasText && styles.eyePassive)}
                                          onMouseDown={showPassword}
                                          onMouseUp={hidePassword}
                                          onMouseLeave={hidePassword}
                                          onTouchStart={showPassword}
                                          onTouchEnd={hidePassword}
        />}
      </div>
    );
})

