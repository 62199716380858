import styles from './PromoInput.module.scss'

export const PromoInput = () => {
  return (
    <div className={styles.promoInput}>
      <input className={styles.input} placeholder={'Enter a promo code'}/>
      <button className={styles.button}>Apply</button>
    </div>

  )
}