import React from 'react'
import {BrightCheckbox} from "../../../../common/BrightCheckbox/BrightCheckbox"
import stylesCommon from 'src/common/styles/commonStyles.module.scss'

type CheckboxInputProps = {
  id: string;
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  hint?: React.ReactNode;
  placeholder: string;
  error?: string | string[] | null;
};

const CheckboxInput: React.FC<CheckboxInputProps> = ({
                                                       id,
                                                       name,
                                                       checked,
                                                       onChange,
                                                       required = false,
                                                       hint,
                                                       placeholder,
                                                       error = ''
                                                     }) => (
  <div className={"form-check mb-3"}>
    <BrightCheckbox
      isError={!!error}
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      required={required}
      data-error-required={`${placeholder} is required`}
    />
    <div className={stylesCommon.checkboxHint}>{hint}</div>
    <div className="invalid-feedback">
      {Array.isArray(error) ? (
        // Если error - это массив строк, выводим каждую строку как элемент списка
        error.map((errMsg, index) => (
          <p key={index}>{errMsg}</p>
        ))
      ) : (
        // Иначе выводим error как строку, если он не null
        error || ''
      )}
    </div>
  </div>
)

export default CheckboxInput
