import american_samoa from 'src/assets/images/flags/american_samoa.png'
import afghanistan from 'src/assets/images/flags/afghanistan.png'
import andorra from 'src/assets/images/flags/andorra.png'

/*Better to do that selector like CurrencySelector, but because of flag names did so.*/

export const flagSelector = (country_code: string) => {
  switch (country_code) {
    case '23':
      return <img src={american_samoa} alt={country_code}/>
    case '26':
      return <img src={andorra} alt={country_code}/>
    case '30':
      return <img src={afghanistan} alt={country_code}/>
    default:
      return <div>{country_code}</div>
  }
}
