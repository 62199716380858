import React from 'react'
import styles from './ErrorMessage.module.scss'
import info_circle from 'src/assets/images/icons/info_circle.svg'

type ErrorMessagesProps = {
  error: string | string[] | null
}

const ErrorMessages: React.FC<ErrorMessagesProps> = ({error}) => {
  if (!error) return null

  return (
    <div className={styles.errorMessage}>
      <img src={info_circle} alt="info circle" />
      {Array.isArray(error) ? (
        // Если error - это массив строк, выводим каждую строку как элемент списка
        error.map((errMsg, index) => (
          <div key={index} className={styles.errorFormText}>
            {errMsg}
          </div>
        ))
      ) : (
        // Otherwise display a single message
        <div className={styles.errorFormText}>{error}</div>
      )}
    </div>
  )
}

export default ErrorMessages
