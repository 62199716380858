// createBalanceSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { RootState } from '../store';


type CreateBalanceResponse = {
  success: boolean;
  data: any[]; // Уточните тип данных
  messages: string | null;
};

type CreateBalanceError = {
  error: any;
  message: string;
};

interface CreateBalanceState {
    success: boolean;
    loading: boolean;
    error: any | null; // Уточните тип ошибки, если возможно
    data: any[]; // Уточните тип массива, если известно
    messages: any | null ;
  }
  

const initialState: CreateBalanceState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchCreateBalance
export interface CreateBalanceParams {
    token: string;
    currencyId: number;
}

export const fetchCreateBalance = createAsyncThunk<
  CreateBalanceResponse,
  CreateBalanceParams,
  { rejectValue: CreateBalanceError }
>(
  'CreateBalance/fetchCreateBalance',
  async ({ token, currencyId }, { rejectWithValue }) => {
    try {
      console.log('Attempting to set create balance`:', `${config.serverUrl}/player/create-balance`, { token, currencyId });

      const response = await axios.post(
        `${config.serverUrl}/player/create-balance`,
        { token, currencyId },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const data = response.data;
      console.log('fetchCreateBalance data (after API response):', data);

      if (response.status === 200 && data.success) {
        console.log('Password updated successfully:', data);
        return { success: data.success, data: data.data, messages: data.messages };
      } else {
        console.error('fetchCreateBalance error:', data.errors || data.message);
        return rejectWithValue({
          error: data.errors || null,
          message: data.message || 'Error set create balance`.',
        });
      }
    } catch (error: any) {
      console.error('fetchCreateBalance error catch:', error);

      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status === 404) {
          console.error('fetchCreateBalance - API endpoint not found:', error.response);
          return rejectWithValue({
            error: 'API endpoint not found.',
            message: 'API endpoint not found.',
          });
        } else if (error.response.status === 400) {
          console.error('fetchCreateBalance - Validation error:', error.response.data.errors);
          return rejectWithValue({
            error: error.response.data.errors,
            message: error.response.data.message || 'Validation error.',
          });
        } else if (error.response.status === 403) {
          // Для ошибки 403 выводим точное сообщение
          console.error('fetchCreateBalance - Forbidden:', error.response.data.errors);
          return rejectWithValue({
            error: error.response.data.errors || 'create balance` is incorrect',
            message: error.response.data.message || 'Access denied.',
          });
        } else {
          console.error('fetchCreateBalance - Server error:', error.response.data);
          return rejectWithValue({
            error: error.response.data.error || 'Unknown error',
            message: error.response.data.message || 'Server error.',
          });
        }
      } else {
        console.error('fetchCreateBalance - Network error:', error.message);
        return rejectWithValue({
          error: 'Network error: Unable to connect to the server.',
          message: 'Network error: Unable to connect to the server.',
        });
      }
    }
  }
);



const CreateBalanceSlice = createSlice({
  name: 'CreateBalance',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchCreateBalance.fulfilled, (state, action: PayloadAction<CreateBalanceResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchCreateBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetState } = CreateBalanceSlice.actions;

export const CreateBalanceReducer = CreateBalanceSlice.reducer;



// ---------
// Selectors
// ---------
export const selectCreateBalanceLoading = (state: RootState) => state.CreateBalanceReducer.loading;
export const selectCreateBalanceSuccess = (state: RootState) => state.CreateBalanceReducer.success;
export const selectCreateBalanceError = (state: RootState) => state.CreateBalanceReducer.error;
export const selectCreateBalanceMessages = (state: RootState) => state.CreateBalanceReducer.messages;
export const selectCreateBalanceData = (state: RootState) => state.CreateBalanceReducer.data;

export const selectCreateBalanceErrorString = (state: RootState): string | null => {
  const error = state.CreateBalanceReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectCreateBalanceErrorArray = (state: RootState): string[] | null => {
  const error = state.CreateBalanceReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
