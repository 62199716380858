import React, {ReactNode} from 'react'
import {Dialog, DialogContent} from '@mui/material'
import x_close from 'src/assets/images/icons/x_close.svg'
import stylesCommon from 'src/components/layouts/ui/modal/Modal.module.scss'

type AuthLayoutProps = {
  children: ReactNode
  show: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
}

const MainLayout: React.FC<AuthLayoutProps> = ({children, show, onClose}) => {

  const handleClose = (event: MouseEvent, reason: string) => {
    // Disable closing if the reason is a click outside the area
    if (reason === 'backdropClick') return
    onClose(event as unknown as React.MouseEvent<HTMLButtonElement | HTMLDivElement>)
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      classes={{
        root: stylesCommon.overlay,
        paper:stylesCommon.modalDialogAuth,
      }}
    >
      <button className={stylesCommon.btnClose} onClick={onClose}>
        <img src={x_close} alt="Close"/>
      </button>

      <DialogContent className={stylesCommon.modalBody}>
        {/* тут будет внутренний контент */}
        {children}
        {/* тут будет внутренний контент */}
      </DialogContent>
    </Dialog>

  )
}

export default MainLayout
