// src/redux/reducers.js
import {combineReducers} from 'redux';
import loginReducer from './reducers/site/loginReducer';
import logoutReducer from './reducers/site/logoutReducer';
import balanceReducer from './reducers/balanceReducer';
import balancesReducer from './reducers/balancesReducer';
import providerReducer from './reducers/providerReducer';
import gameListReducer from './reducers/game/gameListReducer';
import gameGroupsReducer from './reducers/game/gameGroupsReducer';
import bonusListReducer from './reducers/bonus/bonusListReducer';
import vipClubInfoReducer from './reducers/bonus/vipClubInfoReducer';
import listFavoriteGamesReducer from './reducers/player/listFavoriteGamesReducer';
import myProgressReducer from './reducers/player/myProgressReducer';
import listBalancesReducer from './reducers/player/listBalancesReducer';
import updatePersonalInfoReducer from './reducers/player/updatePersonalInfoReducer';
import getPersonalInfoReducer from './reducers/player/getPersonalInfoReducer';
import getListDocumentsReducer from './reducers/document/getListDocumentsReducer';
import getDocumentSettingTypesReducer from './reducers/document/getDocumentSettingTypesReducer';
import getRegistrationFormSettingsReducer from './reducers/setting/getRegistrationFormSettingsReducer';
import {transactionsReducer} from "./slices/transactionsSlice";
import getPlayerProfileSettingsReducer from './reducers/setting/getPlayerProfileSettingsReducer';
import registrationReducer from './reducers/site/registrationReducer';
import registerValidationFirstScreenReducer from './reducers/site/registerValidationFirstScreenReducer';
import {api, apiProxy} from "../api/api";
import {gameReducer} from "./slices/gameSlice";
import {InterfaceReducer} from "./slices/interfaceSlice";
import {favoriteGameReducer} from "./slices/addFavoriteGame";
import {getGameWidgetsReducer} from "./slices/getGameWidgets";
import {updatePasswordReducer} from "./slices/updatePasswordSlice";
import {switchAccountReducer} from "./slices/switchAccountSlice";
import playerBalancesReducer from "./reducers/playerDeposit/playerBalancesReducer.js";
import getPaymentMethodsReducer from "./reducers/payment/getPaymentMethodsReducer.js";
import bonusDepositListReducer from "./reducers/playerDeposit/bonusDepositListReducer.js";
import viewPlayerDepositReducer from "./reducers/playerDeposit/viewReducer";
import codeConfirmationReducer from "./reducers/util/codeConfirmationReducer";
import authformReducer from "./reducers/construct/authformReducer";
import {projectSettingsReducer} from "./slices/getProjectSettingsSlice";
import {setDepositRequestReducer} from "./slices/setDepositRequestSlice";
import {GetDepositResponsibleGamingReducer} from "./slices/getDepositResponsibleGamingSlice";
import {ListAvailableBalancesReducer} from "./slices/listAvailableBalancesSlice"
import {CreateBalanceReducer} from "./slices/createBalanceSlice"
import ipReducer from "./slices/ipSlice"

const rootReducer = combineReducers({
    loginReducer,
    logoutReducer,
    balanceReducer,
    providerReducer,
    balancesReducer,
    gameListReducer,
    gameGroupsReducer,
    listFavoriteGamesReducer,
    bonusListReducer,
    getListDocumentsReducer,
    getDocumentSettingTypesReducer,
    myProgressReducer,
    listBalancesReducer,
    vipClubInfoReducer,
    updatePersonalInfoReducer,
    transactions: transactionsReducer,
    getPersonalInfoReducer,
    getPlayerProfileSettingsReducer,
    [api.reducerPath]: api.reducer,
    game: gameReducer,
    interface: InterfaceReducer,
    favoriteGame: favoriteGameReducer,
    getGameWidgets: getGameWidgetsReducer,
    switchAccount: switchAccountReducer,
    getRegistrationFormSettingsReducer,
    registrationReducer,
    registerValidationFirstScreenReducer,
    playerBalancesReducer,
    getPaymentMethodsReducer,
    [apiProxy.reducerPath]: apiProxy.reducer,
    bonusDepositListReducer,
    viewPlayerDepositReducer,
    codeConfirmationReducer,
    authformReducer,
    updatePasswordReducer,
    projectSettingsReducer,
    setDepositRequestReducer,
    GetDepositResponsibleGamingReducer,
    ListAvailableBalancesReducer,
    CreateBalanceReducer,
    ipReducer
});

export default rootReducer;
