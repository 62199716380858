import React from 'react'
import styles from './BrightCheckbox.module.scss'
import clsx from 'clsx'

type BrightCheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  isError?: boolean;
}

export const BrightCheckbox: React.FC<BrightCheckboxProps> = ({ isError, ...props }) => {
  return (
    <>
    <div className={clsx(styles.brightCheckbox, isError && styles.brightCheckboxError)}>
      <input
        {...props}
        className={'form-check-input'}
        type={'checkbox'}
      />
    </div>
    </>
  )
}