import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from "../../../../hooks"
import {clearErrors, fetchLogin} from 'src/redux/actions/site/loginActions'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {
  selectIsLoadingLogin,
  selectLoginErrors,
  selectLoginErrorsArray
} from '../../../../redux/selectors/site/loginSelectors'
import PasswordInput from '../formComponents/PasswordInput'
import TextInput from '../formComponents/TextInput'
import stylesCommon from "../AuthCommon.module.scss"
import styles from './Login.module.scss'
import {NavigationButtonsContainer} from "../../../../common/NavigationButtonsContainer/NavigationButtonsContainer"
import login_background from 'src/assets/images/login_background.jpg'
import Grid from '@mui/material/Grid2'
import {LoginNavigationButtons} from "../formComponents/LoginNavigationButtons/LoginNavigationButtons"
import {SocialAuth} from "../formComponents/SocialAuth/SocialAuth"
import {SubmitButton} from "../formComponents/SubmitButton/SubmitButton"
import mail_01_passive from 'src/assets/images/icons/mail_01_passive.svg'
import mail_01 from 'src/assets/images/icons/mail_01.svg'

// Определение интерфейсов для состояний
interface FormState {
  email?: string;
  password?: string;
}

interface ClientErrors {
  required: { [key: string]: string };
}

interface LoginProps {
  handleButtonActiveTabClick: (tab: 'login' | 'signup') => void;
}

const Login: React.FC<LoginProps> = ({handleButtonActiveTabClick}) => {
  const dispatch = useAppDispatch()
  // Навигация
  const navigate = useNavigate()

  const loginErrors: any = useSelector(selectLoginErrors)
  const loginErrorsArray: string[] | null = useSelector(selectLoginErrorsArray)
  const isLoadingLogin: boolean = useSelector(selectIsLoadingLogin)

  const [ip, setIp] = useState<string>('')
  const [clientErrors, setClientErrors] = useState<ClientErrors>({required: {}})
  const [emailFieldErrors, setEmailFieldErrors] = useState<string[] | null>(null)
  const [passwordFieldErrors, setPasswordFieldErrors] = useState<string[] | null>(null)
  const [formState, setFormState] = useState<FormState>({})
  const [initialState, setInitialState] = useState<FormState>({})
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    const initialFormValues: FormState = {
      email: '',
      password: ''
    }

    setFormState(initialFormValues)
    setInitialState(initialFormValues)
  }, [])

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get<{ ip: string }>('https://api.ipify.org?format=json')
        setIp(response.data.ip)
      } catch (error) {
        console.error('Error fetching IP:', error)
      }
    }
    fetchIp()
  }, [])

  // Set validation errors
  useEffect(() => {
    if (loginErrors) {
      if (loginErrors.email) {
        setEmailFieldErrors(loginErrors.email)
      } else {
        setEmailFieldErrors(null)
      }

      if (loginErrors.password) {
        setPasswordFieldErrors(loginErrors.password)
      } else {
        setPasswordFieldErrors(null)
      }
    }
  }, [loginErrors])

  useEffect(() => {
    // Очистка ошибок при каждом монтировании компонента
    dispatch(clearErrors())
    setEmailFieldErrors(null)
    setPasswordFieldErrors(null)

    // Очистка ошибок при размонтировании
    return () => {
      dispatch(clearErrors())
      setEmailFieldErrors(null)
      setPasswordFieldErrors(null)
    }
  }, [])


  // Login request
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const email = formState.email
    const password = formState.password

    if (clientValidation() && email && password) {
      dispatch(fetchLogin(email, password, ip, navigate))
    } else {
      console.log('Validation failed')
    }
  }

  const clientValidation = (): boolean => {
    const formElements = formRef.current?.elements
    const newErrors: ClientErrors = {required: {}}
    let formIsValid = true

    if (formElements) {
      Array.from(formElements).forEach(field => {
        const input = field as HTMLInputElement
        if (input.required && !input.value.trim()) {
          formIsValid = false
          const errorMessage = input.getAttribute('data-error-required') || 'This field is required.'
          newErrors.required[input.name] = errorMessage
        }
      })
    }

    setClientErrors(newErrors)
    return formIsValid
  }

  const handleButtonLoginAccauntClick = () => {
    clientValidation()
  }

  const handleInputChange = (code: string, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    const updatedFormState = {
      ...formState,
      [code]: value,
    }
    setFormState(updatedFormState)
    setIsEditing(JSON.stringify(updatedFormState) !== JSON.stringify(initialState))

    setClientErrors((prevErrors) => {
      const updatedErrors = {...prevErrors}

      if (value.trim() !== '') {
        delete updatedErrors.required[code]
      } else {
        const errorMessage = e.target.getAttribute('data-error-required') || 'This field is required.'
        updatedErrors.required[code] = errorMessage
      }

      return updatedErrors
    })
  }

  // Кнопка закрытия на первом экране
  /* const handleClickCloseButtonInFirstScreen = () => {
     if (location.key !== 'default') {
       navigate(-1) // Переход на предыдущую страницу, если история есть
     } else {
       navigate('/') // Переход на главную, если истории нет
     }
   }*/

  // Render View
  const renderView = (): JSX.Element => (
    <div className={styles.login}>

      <Grid container columns={3}>
        {/* Left block - 1/3 width */}
        <Grid size={1}>
          <div className={stylesCommon.leftContent} style={{
            "--background": `url(${login_background})`,
          } as React.CSSProperties}>
            <div className={stylesCommon.text}>
              Play and win big wits Sloterra!

            </div>
          </div>
        </Grid>

        {/* Right block - 2/3 width */}
        <Grid size={2}>
          <div className={stylesCommon.rightContent}>

            {/* Блок с кнопками Логин и Регистрация */}
            <NavigationButtonsContainer>
              <LoginNavigationButtons
                onLoginClick={() => handleButtonActiveTabClick('login')}
                onRegisterClick={() => handleButtonActiveTabClick('signup')}
                activeButton={'login'}
              />
            </NavigationButtonsContainer>

            {/* Блок с формой */}
            <form onSubmit={handleSubmit} ref={formRef}>

              <div className={styles.formInputs}>
                <TextInput
                  id="email"
                  name="email"
                  value={formState.email || ''}
                  onChange={(e) => handleInputChange('email', e)}
                  required
                  placeholder="Enter your email"
                  passiveIcon={mail_01_passive}
                  activeIcon={mail_01}
                />

                <PasswordInput
                  id="password"
                  name="password"
                  value={formState.password || ''}
                  onChange={(e) => handleInputChange('password', e)}
                  required
                  placeholder="Enter your password"
                  error={clientErrors.required.password || passwordFieldErrors}
                />
              </div>


              {/* Блок с кнопкой и ссылкой */}
              <SubmitButton onClick={handleButtonLoginAccauntClick} isLoading={isLoadingLogin} text={'Login'}/>
              <div className={styles.forgotPasswordContainer}>
                <div className={styles.forgetText}>Forgot password?</div>
              </div>

              {loginErrorsArray && (!emailFieldErrors && !passwordFieldErrors) && (
                <div className="alert alert-danger" role="alert">
                  {loginErrorsArray.map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </div>
              )}

            </form>
            <SocialAuth variant={'login'}/>
          </div>
        </Grid>
      </Grid>
      {/* тут будет внутренний контент */}
    </div>
  )

  return (
    renderView()
  )
}

export default Login
