import React, {useEffect, useState} from 'react'
import axios from 'axios'
import config from '../../../config'
import {useTranslation} from "react-i18next"
import styles from './NotFound.module.scss'
import number_4 from 'src/assets/images/icons/404/4.svg'
import number_0 from 'src/assets/images/icons/404/0.svg'
import {ButtonStyled} from "../../../common/ButtonStyled/ButtonStyled"
import clubs_poker_chip_full from 'src/assets/images/clubs_poker_chip_full.png'
import diamonds_poker_chip_full from 'src/assets/images/diamonds_poker_chip_full.png'

interface ErrorMessage {
  errorMessage: string;
}

interface ErrorResponse {
  error: string;
}

export const NotFound: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);
  const {t} = useTranslation()

  
  useEffect(() => {
    document.title = "404 - Not Found";
    //Если ответ сервера успешен (коды статуса 2xx), этот блок выполняется.
    axios.get(`${config.serverUrl}/status/404`)
      
    .then((response) => {
        setErrorMessage({ errorMessage: response.data.error });
      })
      .catch((error) => {
        console.log('response.data.error', error);
        if (axios.isAxiosError(error)) {
          // Если сервер возвращает ошибку (например, коды статуса 4xx или 5xx), этот блок будет выполнен.
          if (error.response) {
            setErrorMessage({ errorMessage: error.response.data.error });
          } else {
            // Если ошибка не связана с ответом от сервера
            console.error('Fetch error:', error);
            setErrorMessage({ errorMessage: "An error occurred while fetching the error status." });
          }
        } else {
          // Обработка других ошибок
          console.error('Unexpected error:', error);
          setErrorMessage({ errorMessage: "An unexpected error occurred." });
        }
      });
  }, []);




  return (
    <div className={styles.component}>
      <div className={styles.numbers}>
        <img src={number_4} alt=""/>
        <img src={number_0} alt=""/>
        <img src={number_4} alt=""/>
      </div>
      <div className={styles.notFound}>{t('Page not found')}</div>
      <div className={styles.text}>{t('Sorry, we can’t find the page you are looking for')}</div>
      <ButtonStyled to="/" className={styles.button}>{t('Go to main')}</ButtonStyled>
      <img src={clubs_poker_chip_full} alt="clubs_poker_chip" className={styles.clubs_poker_chip}/>
      <img src={diamonds_poker_chip_full} alt="diamonds_poker_chip" className={styles.diamonds_poker_chip}/>
    </div>
  );
}
