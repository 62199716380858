import React, {useState} from 'react'
import styles from './SelectInput.module.scss'
import clsx from 'clsx'
import {Select, MenuItem, FormControl, InputLabel, FormHelperText, SelectChangeEvent} from '@mui/material'
import chevron_down from 'src/assets/images/icons/chevron_down.svg'
import chevron_down_flipped from 'src/assets/images/icons/chevron_down_flipped.svg'
import {CurrencySelector} from "../../../../../common/CurrencySelectors/CurrencySelectors"
import {flagSelector} from "../../../../../common/flagSelector"
import check from 'src/assets/images/icons/check.svg'

type Option = {
  id: string 
  name: string 
} 

type SelectInputProps = {
  id: string 
  name: string 
  value: string 
  onChange: (e: SelectChangeEvent<string>) => void 
  required?: boolean 
  options: Option[] 
  placeholder: string 
  error?: string | string[] | null 
  variant?: 'currency' | 'country' 
} 

const SelectInput: React.FC<SelectInputProps> = ({
                                                   id,
                                                   name,
                                                   value = '',
                                                   onChange,
                                                   required = false,
                                                   options,
                                                   placeholder,
                                                   error = '',
                                                   variant
                                                 }) => {
  const [isOpen, setIsOpen] = useState(false)

  const selectedOption = options.find(option => option.id.toString() === value)

  return <div className={styles.selectInput}>
    <Select
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      displayEmpty
      renderValue={(selected) => {
        if (variant === 'currency') {
          return 'Set currency' // Display static text for the "currency" option
        }
        if (variant === 'country' && selectedOption) {
          return (
            <div className={styles.optionContainer}>
              {flagSelector(selectedOption.id)}
              <div>{selectedOption.name}</div>
            </div>
          )
        }
        // Otherwise, we show the selected value or placeholder
        return selectedOption ? selectedOption.name : placeholder
      }}
      classes={{
        root: styles.select, // styles for closed select
      }}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': { // styles for the border of open select
          border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--border-action-focus)',
        },
        '&.Mui-focused': {
          outline: 'none',
          boxShadow: 'none',
        },
      }} // replaced default border
      MenuProps={{
        sx: {
          '& .MuiList-root': { // Styles for the list of options
            padding: '4px 0',
          },
          '& .MuiPaper-root': {
            margin: '4px 0',
            backgroundColor: 'var(--background-input-normal)',
            color: 'var(--text-base-primary)',
            borderRadius: 'var(--radius-12)',
            border: '1px solid var(--border-action-normal)',
            boxShadow: 'none',
          },
          '& .MuiMenuItem-root': {  // Styles for menu items
            margin: '2px 6px',
            padding: '9px 10px',
            borderRadius: 'var(--radius-8)',
            '&:hover': {
              backgroundColor: 'var(--background-surface-subtle-primary)', // hover for unselected item
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'var(--background-surface-subtle-primary)', // hover for selected item
            },
          },
          '& .Mui-selected': {
            backgroundColor: 'var(--background-surface-subtle-primary)', // Styles for the selected item
          },
          '& .MuiMenuItem-root.Mui-selected': {
            backgroundColor: 'var(--background-surface-subtle-primary)', // extra style for selected item, happens when menu is opened, but selected nothing by click
          },
        },
      }}
      IconComponent={() => (
        <div className={styles.chevronContainer}>
          {variant === 'currency' && selectedOption?.name && <>
              <CurrencySelector currencyCode={selectedOption?.name} size={20}/>
              <div className={styles.currencyName}>{selectedOption?.name}</div>
          </>}
          <img
            src={isOpen ? chevron_down_flipped : chevron_down}
            alt="chevron"
            className={styles.chevronIcon}
          />
        </div>

      )}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      {options.map((option) => (
        <MenuItem
          key={option.id}
          value={option.id}
        >
          <div className={styles.optionContainer}>
            {variant === 'country' && flagSelector(String(option.id))}
            {variant === 'currency' && <CurrencySelector currencyCode={option.name} size={20}/>}
            {option.name}
            {option.id === selectedOption?.id && <img src={check} alt="check" className={styles.checkIcon}/>}
          </div>

        </MenuItem>
      ))}
    </Select>
    <div className="invalid-feedback">
      {Array.isArray(error) ? (
        // Если error - это массив строк, выводим каждую строку как элемент списка
        error.map((errMsg, index) => (
          <p key={index}>{errMsg}</p>
        ))
      ) : (
        // Иначе выводим error как строку, если он не null
        error || ''
      )}
    </div>
    {/* <div className="invalid-feedback">
      {error || ''}
    </div> */}
  </div>
}

export default SelectInput
