//ModalPlayer
// Deposit.js
import React from 'react'
import {useSelector} from 'react-redux'
import {useAppDispatch} from "../../../hooks"
import PlayerDeposit from "../../pages/PlayerDeposit/PlayerDeposit"
import Modal from "../ui/modal/Modal"
import {selectPreviousView} from '../../../redux/selectors/playerDeposit/viewSelectors'
import {setPreviousView} from '../../../redux/actions/playerDeposit/viewActions'
import stylesModal from '../ui/modal/Modal.module.scss'
import arrow_left from 'src/assets/images/icons/arrow_left.svg'

interface ModalPlayerDepositProps {
    isShowModal: boolean;
    onCloseModal: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
    currencyId?: number | null;
}


const ModalPlayerDeposit: React.FC<ModalPlayerDepositProps> = ({isShowModal, onCloseModal, currencyId})  => {

    const dispatch = useAppDispatch()
    const previousView = useSelector(selectPreviousView)

   console.log("previousView:", previousView);


    const handleModalBackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        dispatch(setPreviousView())
    }
    
    return (
        <>
            <Modal
                show={isShowModal}
                onClose={onCloseModal}
                body={isShowModal && <>
                  { previousView && <button className={stylesModal.btnPrev} onClick={handleModalBackClick}>
                        <img src={arrow_left} alt="arrow_left"/>
                    </button> }
                    <PlayerDeposit propCurrencyId={currencyId}/>
                </>}
                showHeader={false}

            />
        </>
    );
};

export default ModalPlayerDeposit;
