import { useLocation, useParams, useNavigate } from 'react-router-dom'
import MainLayout from '../MainLayout/MainLayout'
import AuthLayout from '../AuthLayout/AuthLayout'
import React, { ReactNode } from 'react'

type LayoutWrapperProps = {
    children: ReactNode;
};

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
    const location = useLocation();
    const { param } = useParams();  // Параметр маршрута

    const isAuthPath = location.pathname.startsWith('/auth');
    const navigate = useNavigate()

    // Получаем значение из state из App путей
    const fromRedirect = location.state?.fromRedirect;

    // console.log('Current path:', location.pathname);  // Логируем текущий путь
    // console.log('Param:', param);

    // Проверка на /auth без параметров
    // if (location.pathname === '/auth') {
    //     console.log('LayoutWrapper', 'AuthLayout /auth');
    //     return <AuthLayout>{children}</AuthLayout>;
    // }

    // // Проверка для путей с параметром, например /auth/login или /auth/signup
    // if (param === 'login' || param === 'signup') {
    //     console.log('LayoutWrapper', `AuthLayout /auth/${param}`);
    //     return <AuthLayout>{children}</AuthLayout>;
    // }

    if (location.pathname.startsWith('/robots.txt')) {
        return <>{children}</>;
    }

    // Если ни одно из условий не подошло, используем MainLayout
    return <>
        <MainLayout>
            {!isAuthPath && children}
        </MainLayout>
        {/* Modal for /auth */}
        {isAuthPath && (
            <AuthLayout
                show={isAuthPath}
                onClose={() => {
                    // window.history.back(); // Return to the previous page
                    if (location.key !== 'default') {
                        // Если предыдущая страница только для игрока, и имеет редирект на аут пейдж
                        if (fromRedirect) {
                            navigate('/')
                        } else {
                            navigate(-1) // Переход на предыдущую страницу, если история есть
                        }
                    } else {
                        navigate('/') // Переход на главную, если истории нет
                    }
                }}
            >
                {children}
            </AuthLayout>
        )}
    </>;
};

export default LayoutWrapper;
