// bonusListActions.js
import axios from 'axios';
import config from '../../../config';
import { selectIp } from '../../slices/ipSlice';

export const ACTION_GET_BONUSLIST_REQUEST = 'ACTION_GET_BONUSLIST_REQUEST';
export const ACTION_GET_BONUSLIST_SUCCESS = 'ACTION_GET_BONUSLIST_SUCCESS';
export const ACTION_GET_BONUSLIST_FAILURE = 'ACTION_GET_BONUSLIST_FAILURE';

// Экшены для получения данных
export const actionGetBonusListRequest = () => ({
  type: ACTION_GET_BONUSLIST_REQUEST,
});

export const actionGetBonusListSuccess = (data, messages) => ({
  type: ACTION_GET_BONUSLIST_SUCCESS,
  payload: { data, messages },
});

export const actionGetBonusListFailure = (error) => ({
  type: ACTION_GET_BONUSLIST_FAILURE,
  payload: error,
});

// Экшен для получения списка бонусов с расширенной обработкой ошибок
export const fetchGetBonusList = (token, event, language = null) => async (dispatch, getState) => {
  const ip = selectIp(getState());

  if (!ip) {
    throw new Error('Failed to fetch IP');
  }else{
    console.info('fetchGetBonusList fetching IP:', ip);
  }
  
  // Перед отправкой запроса
  dispatch(actionGetBonusListRequest());

  try {
      console.log('Attempting to fetchGetBonusList:', `${config.serverUrl}/bonus/list`, { token, event, language, ip });

      const response = await axios.get(`${config.serverUrl}/bonus/list`, {
          params: { token, event, language, ip },
          headers: {
              'Content-Type': 'application/json; charset=UTF-8',
          },
      });

      const data = response.data;
      console.log('fetchGetBonusList data (after ajax):', data);

      if (response.status === 200 && data.success) {
          dispatch(actionGetBonusListSuccess(data.data, data.messages));
      } else {
          console.error('fetchGetBonusList error:', data.errors);
          dispatch(actionGetBonusListFailure(data.errors, data.message || 'Error fetching bonus list.'));
      }
  } catch (error) {
      if (error.response) {
          console.error(`Error: ${error.response.status}`, error.response.data);

          if (error.response.status === 404) {
              dispatch(actionGetBonusListFailure(null, 'API endpoint not found.'));
          } else {
              dispatch(actionGetBonusListFailure(error.response.data.error, error.response.data.message || 'Server error.'));
          }
      } else {
          console.error('fetchGetBonusList network error:', error.message);
          dispatch(actionGetBonusListFailure(null, 'Network error: Unable to connect to the server.'));
      }
  }
};


// Экшены для WebSocket
export const connectWebSocket = (token) => {
  return (dispatch, getState) => {
    // Получаем IP из состояния через селектор
    const ip = selectIp(getState());

    if (!ip) {
      console.error('Failed to fetch IP');
      // throw new Error('Failed to fetch IP');
    }

    const ws = new WebSocket(`${config.serverWebsocketUrl}?ip=${ip}`);

    ws.onopen = () => {
      console.log('Connected to WebSocket server getBonusList');
      ws.send(JSON.stringify({ type: 'getBonusList', token, ip }));
      
    };

    ws.onmessage = (event) => {
      console.log('WebSocket message received getBonusList:', event.data);
      try {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data.type === 'getBonusList') {
          console.log('BonusList received:', data.data.data);
          dispatch(actionGetBonusListSuccess(data.data.data, data.data.messages));
        } else if (data.type === 'error') {
          dispatch(actionGetBonusListFailure(data.message));
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error.message);
      }
    };

    ws.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      ws.close();
    };
  };
};
