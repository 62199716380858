import React, {ReactNode} from "react"
import styles from './NavigationButtonsContainer.module.scss'
import clsx from 'clsx'

type Props = {
  children: ReactNode
  variant?: 'dropdown' | 'profile'
}

export const NavigationButtonsContainer = ({children, variant}: Props) => {
  return (
    <div className={clsx(
      styles.navigationButtonsContainer,
      variant === 'profile' && styles.profileVariant
      
    )}>
      {children}
    </div>
  )
}