import React, {useState} from 'react'
import {format} from 'date-fns'
import {DayPicker} from './DayPicker'
import styles from './DataInput.module.scss'

type TextInputProps = {
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder: string;
  error?: string | string[] | null;
};

const DataInput: React.FC<TextInputProps> = ({
  id,
  name,
  value = '',
  onChange,
  required = false,
  placeholder,
  error = '',
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(value ? new Date(value) : null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    console.log(date)
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      onChange({
        target: {
          name: name,
          value: formattedDate, // Преобразование в формат YYYY-MM-DD
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const onChangeLocal = (date: string | undefined) => {
    if (date) {
      // Обновляем состояние родителя (или текущего компонента)
      setSelectedDate(new Date(date)); // Преобразуем строку в Date

      // Форматируем дату для передачи дальше
      const formattedDate = format(new Date(date), 'yyyy-MM-dd');

      // Вызываем onChange родительского компонента
      onChange({
        target: {
          name: name, // Имя поля (важно для формы)
          value: formattedDate, // Передаём строку в формате 'yyyy-MM-dd'
        },
      } as React.ChangeEvent<HTMLInputElement>);
    } else {
      setSelectedDate(null); // Сбрасываем состояние, если нет даты
    }
  }

  return (
    <div className={styles.dateInput}>
  {/*    <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        id={id}
        name={name}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        required={required}
        placeholderText={placeholder}
        dateFormat="yyyy-MM-dd"
      />*/}
      <DayPicker
        errorMessage={'error message'}
        // label={'day picker'}
        onChange={onChangeLocal}
        selected={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : undefined}
      />
      <div className="invalid-feedback">
        {Array.isArray(error) ? (
          error.map((errMsg, index) => (
            <p key={index}>{errMsg}</p>
          ))
        ) : (
          error || ''
        )}
      </div>
    </div>
  );
};

export default DataInput;
