import React, {useEffect, useState} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import styles from "./Game.module.scss"
import stylesCommon from "src/common/styles/commonStyles.module.scss"
import {LinearProgress} from "@mui/material"
import {useSelector} from "react-redux"
import {selectIsAuthenticated, selectToken} from "../../../redux/selectors/site/loginSelectors"
import {useGetGameInfoQuery} from "../../../api/api"
import {BrightBreadcrumbs} from "../../../common/BrightBreadcrumbs/BrightBreadcrumbs"
import {ScrollButton} from "../../../common/ScrollButton/ScrollButton"
import {BrightTabs} from "../../../common/BrightTabs/BrightTabs"
import star_01 from "../../../assets/images/icons/star_01.svg"
import expand_01 from "src/assets/images/icons/expand_01.svg"
import star_fill from "src/assets/images/icons/star_fill.svg"
import {addFavoriteGame} from "../../../redux/slices/addFavoriteGame"
import {useAppDispatch} from "../../../hooks"

export const Game = () => {
  const {slug} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = useSelector(selectToken)
  const navigate = useNavigate()
  const isAuthenticated = useSelector(selectIsAuthenticated)

  const mode = searchParams.get('mode')

  const [activeTab, setActiveTab] = useState<number | null>(null)

  const {data: gameInfo, isLoading, refetch} = useGetGameInfoQuery(
    {
      token: token ? token : '',
      slug: slug ? slug : '',
      mode: mode ? mode : undefined
    }
  )

  const dispatch = useAppDispatch()


  const handleStarClick = async (gameId: number | undefined) => {
    if (!gameId) return
    if (token) {
      await dispatch(addFavoriteGame({gameId, token}))
      refetch()
    }
  }

  // set active tab when component is loaded
  useEffect(() => {
    if (!isLoading && activeTab === null) {
      setActiveTab(mode === 'demo' ? 0 : 1)
    }
  }, [isLoading, mode, activeTab])

  // change search params when active tab is changed
  useEffect(() => {
    if (activeTab !== null) {
      const newMode = activeTab === 0 ? 'demo' : null
      setSearchParams((prev) => {
        const updatedParams = new URLSearchParams(prev)
        if (newMode) {
          updatedParams.set('mode', newMode)
        } else {
          updatedParams.delete('mode')
        }
        return updatedParams
      })
    }
  }, [activeTab, setSearchParams])

  const breadcrumbItems = [
    {label: 'Home page', path: '/', isLink: true},
    {label: 'Games', path: '/games', isLink: true},
    {label: gameInfo?.data.name || '', isLink: false}
  ]

  const tabs = [
    {label: "Play for fun"},
    {label: "Play for real"},
  ]

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }


  const onDemoClick = () => {
    setActiveTab(0)
  }

  const onSignUpClick = () => {
    navigate('/auth/signup')
  }

  return (
    <div className={styles.gameContainer}>


      {isLoading
        ? <LinearProgress/>
        : <>
          <BrightBreadcrumbs items={breadcrumbItems}/>
          <div className={styles.game}>
            <div className={styles.nameContainer}>
              <div className={stylesCommon.name}>
                <ScrollButton onClick={() => {
                  navigate(-1)
                }} direction={"left"}/>
                <div className={styles.gameName}>{gameInfo?.data.name}</div>
              </div>
              <div className={styles.controls}>
                {
                  gameInfo?.data.isFavorite
                }
                <img src={gameInfo?.data.isFavorite ? star_fill : star_01} alt="star_01"
                     onClick={() => handleStarClick(gameInfo?.data.id)}/>
                <img src={expand_01} alt="expand_01"/>
              </div>
            </div>
            <div className={styles.iframe}>
              {
                !isAuthenticated && activeTab === 1
                  ? <div className={styles.buttonsContainer}>
                    <button className={styles.buttonDemo} onClick={onDemoClick}>Demo</button>
                    <button className={styles.buttonSignUp} onClick={onSignUpClick}>Sign up</button>
                  </div>
                  : <iframe
                    src={gameInfo?.data.game_url ? gameInfo?.data.game_url : "https://en.wikipedia.org/wiki/Main_Page"}
                    title="Game"
                  ></iframe>
              }

            </div>
            <div className={styles.tabsContainer}>
              {activeTab !== null && (
                <BrightTabs
                  tabs={tabs}
                  activeTab={activeTab}
                  onChange={handleChange}
                  className={styles.tabs}
                />
              )}
            </div>

          </div>
        </>
      }


    </div>
  )
}

/*todo: fix return button*/
/*todo: add portal functionality & SnackBar if game already in favorites */