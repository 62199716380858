// listAvailableBalancesSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config';
import { RootState } from '../store';


type ListAvailableBalancesResponse = {
  success: boolean;
  data: any[];
  messages: string | null;
};

type ListAvailableBalancesError = {
  error: any;
  message: string;
};

interface ListAvailableBalancesState {
    success: boolean;
    loading: boolean;
    error: any | null;
    data: any[];
    messages: string | null;
  }
  

const initialState: ListAvailableBalancesState = {
  success: false,
  loading: false,
  error: null,
  data: [],
  messages: null,
};


// Типизация для fetchListAvailableBalances
interface ListAvailableBalancesParams {
  token: string;
  params?: {
    category?: string;
    code?: string;
  };
}

export const fetchListAvailableBalances = createAsyncThunk<
  ListAvailableBalancesResponse,
  ListAvailableBalancesParams,
  { rejectValue: ListAvailableBalancesError }
>(
  'ListAvailableBalances/fetchListAvailableBalances',
  async ({ token, params = {} }, { rejectWithValue }) => {
    try {
      console.log('Attempting to fetchListAvailableBalances:', `${config.serverUrl}/player/list-available-balances`, { token, params });

      const response = await axios.get<ListAvailableBalancesResponse>(
        `${config.serverUrl}/player/list-available-balances`,
        {
          params: {
            ...params, // Ваши дополнительные параметры
            token,     // Токен авторизации как часть query-параметров
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Attempting to fetchListAvailableBalances response.data:', response.data);

      return response.data;
    } catch (error) {
      console.error('Error fetching list available balances:', error);

      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue({
          error: error.response.data,
          message: error.response.data.message || 'Failed to fetch list available balances.',
        });
      }

      return rejectWithValue({
        error: 'An unknown error occurred.',
        message: 'An unknown error occurred.',
      });
    }
  }
);



const ListAvailableBalancesSlice = createSlice({
  name: 'ListAvailableBalances',
  initialState,
  reducers: {
    resetState: (state) => {
      state.success = initialState.success;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.data = initialState.data;
      state.messages = initialState.messages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListAvailableBalances.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.messages = null;
        state.success = false;
      })
      .addCase(fetchListAvailableBalances.fulfilled, (state, action: PayloadAction<ListAvailableBalancesResponse>) => {
        state.loading = false;
        state.success = action.payload.success;
        state.data = action.payload.data;
        state.messages = action.payload.messages;
      })
      .addCase(fetchListAvailableBalances.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error || null;
        state.messages = action.payload?.message || 'Unknown error occurred.';
        state.success = false;
      });
  },
});

export const { resetState } = ListAvailableBalancesSlice.actions;

export const ListAvailableBalancesReducer = ListAvailableBalancesSlice.reducer;



// ---------
// Selectors
// ---------
export const selectListAvailableBalancesLoading = (state: RootState) => state.ListAvailableBalancesReducer.loading;
export const selectListAvailableBalancesSuccess = (state: RootState) => state.ListAvailableBalancesReducer.success;
export const selectListAvailableBalancesError = (state: RootState) => state.ListAvailableBalancesReducer.error;
export const selectListAvailableBalancesMessages = (state: RootState) => state.ListAvailableBalancesReducer.messages;
export const selectListAvailableBalancesData = (state: RootState) => state.ListAvailableBalancesReducer.data;

export const selectListAvailableBalancesErrorString = (state: RootState): string | null => {
  const error = state.ListAvailableBalancesReducer.error;

  if (Array.isArray(error)) {
    return error.join('\n');
  }

  if (typeof error === 'object' && error !== null) {
    return Object.entries(error)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  }

  return error;
};

export const selectListAvailableBalancesErrorArray = (state: RootState): string[] | null => {
  const error = state.ListAvailableBalancesReducer.error;
  let errorsArray: string[] = [];

  if (Array.isArray(error)) {
    errorsArray = error.map((err) => `${err}`);
  } else if (typeof error === 'object' && error !== null) {
    errorsArray = Object.entries(error)
      .flatMap(([key, value]) =>
        Array.isArray(value) ? value.map((val) => `${key}: ${val}`) : `${key}: ${value}`
      );
  } else if (error) {
    errorsArray = [error];
  }

  return errorsArray.length > 0 ? errorsArray : null;
};
