import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {selectBalances} from 'src/redux/selectors/balancesSelectors'
import {selectIsAuthenticated, selectToken} from '../../../redux/selectors/site/loginSelectors'
import {LanguageDropdown} from "../../elems/LanguageDropdown/LanguageDropdown"
import {connectWebSocket, fetchBalances} from 'src/redux/actions/balancesActions'
import {useAppDispatch, useAppSelector} from "../../../hooks"
import {interfaceSelector, setListBalancesDropdownShown} from "../../../redux/slices/interfaceSlice"
import Site_logo from 'src/assets/images/Site_logo.png'
import styles from './Navigation.module.scss'
// import stylesModal from '../ui/modal/Modal.module.scss'
import {ButtonStyled} from "../../../common/ButtonStyled/ButtonStyled"
// import PlayerDeposit from "../../pages/PlayerDeposit/PlayerDeposit"
// import Modal from "../ui/modal/Modal"
// import {selectPreviousView} from '../../../redux/selectors/playerDeposit/viewSelectors'
import {setPreviousView} from '../../../redux/actions/playerDeposit/viewActions'
import {AppBarStyled} from "../../../common/AppBarStyled"
import {Toolbar} from "@mui/material"
import Box from "@mui/material/Box"
import {Notification} from "../../elems/Notification/Notification"
import {InputWithIcon} from "src/common/InputWithIcon/InputWithIcon"
import {ProfileDropdown} from "../../elems/ProfileDropdown/ProfileDropdown"
import {ListBalancesDropdown} from "../../elems/ListBalancesDropdown/ListBalancesDropdown"
import {MoneyBlock} from "../../../common/MoneyBlock/MoneyBlock"
import {useTranslation} from "react-i18next"
import ModalPlayerDeposit from "../modals/ModalPlayerDeposit"
import search_md from 'src/assets/images/icons/search_md.svg'

const Navigation: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState('')

  const isAuthenticated = useSelector(selectIsAuthenticated)
  const token = useSelector(selectToken)
  const balances = useSelector(selectBalances)
  // const previousView = useSelector(selectPreviousView)

  const {isListBalancesDropdownShown} = useAppSelector(interfaceSelector)
  const {t} = useTranslation()

  useEffect(() => {
    if (isListBalancesDropdownShown) {
      setActiveDropdown('none') // Close all local menus
    }
  }, [isListBalancesDropdownShown])

  const [activeDropdown, setActiveDropdown] = useState<'none' | 'star' | 'language' | 'profile' | 'mainBalance' | 'bonusBalance'>('none')

  const handleDropdownToggle = (type: 'star' | 'language' | 'profile' | 'mainBalance' | 'bonusBalance') => {
    if (type !== 'mainBalance' && type !== 'bonusBalance') {
      // Opening the local menu
      dispatch(setListBalancesDropdownShown(false)) // Close the Redux menu
      setActiveDropdown((prev) => (prev === type ? 'none' : type)) // Toggle local menu
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    const isInsideDropdown = Object.entries(dropdownRefs).some(([key, ref]) => {
      return ref.current && ref.current.contains(event.target as Node)
    })

    if (!isInsideDropdown) {
      setActiveDropdown('none') // Close local menus
      dispatch(setListBalancesDropdownShown(false)) // Close BalancesDropdown
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])


  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchBalances(token))
      const closeWebSocket = dispatch(connectWebSocket(token))
      return () => {
        closeWebSocket()
      }
    }
  }, [dispatch, token, isAuthenticated])


  const handleModalBackClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(setPreviousView())
  }

  const handleClosePlayerDepositModal = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    e.preventDefault()
    setShowModal(false)
  }

  const dropdownRefs = {
    star: useRef<HTMLDivElement>(null),
    language: useRef<HTMLDivElement>(null),
    profile: useRef<HTMLDivElement>(null),
    mainBalance: useRef<HTMLDivElement>(null),
    bonusBalance: useRef<HTMLDivElement>(null),
  }

  // ---------------
  // TODO сделать какой то блок Alert в котором будет выводиться ошибка логаута
  // ---------------

  const onSearch = () => {
    console.log(`search game ${searchValue}`)
  }

  return (
    <>
      <AppBarStyled position="static" className={styles.navigation}>
        <Toolbar className={styles.toolbar}>
          <Box className={styles.logoSearch}>
            <Link to="/">
              <img src={Site_logo} alt="site_logo" className={styles.siteLogo}/>
            </Link>
            <InputWithIcon value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={t('Browse games')}
                           onKeyUp={onSearch} className={styles.search} icon={search_md}/>
          </Box>
          <Box className={styles.navbar}>
            {isAuthenticated
              ? (
                <>
                  <Box>
                    <div
                      ref={dropdownRefs.star}
                      className={"dropdown" + (activeDropdown === 'star' ? " show" : "")}
                    >
                      <div onClick={() => handleDropdownToggle('star')} className={styles.tempStar}>*</div>
                      {/* this is for old menu, will be removed when cabinet be done */}
                      <div
                        className={"dropdown-menu dropdown-menu-left dropdown-menu-arrow" + (activeDropdown === 'star' ? " show" : "")}>
                        <Link className="dropdown-item" to="/notifications">Notifications</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/favorite-games">Favorite Games</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/accounts">Accounts</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/personal-information">Personal Information</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/documents">Documents</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/transactions">Transaction History</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/bonus-history">Bonus History</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/responsible-gaming">Responsible Gaming</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="/deposit">Deposit</Link>
                      </div>
                    </div>
                  </Box>
                  <Box ref={dropdownRefs.profile}>
                    <ProfileDropdown
                      showDropdown={activeDropdown === 'profile'}
                      setShowDropdown={() => handleDropdownToggle('profile')}
                    />
                  </Box>
                  {/* Balances */}
                  {balances ? (
                    Object.entries(balances).map(([key, balance]: [string, any]) => {
                      // Main balance
                      if (key === 'main_balance') {
                        return (
                          <Box sx={{position: "relative"}}>
                            <Box ref={dropdownRefs.mainBalance}>
                              <ListBalancesDropdown
                                balance={balance}
                              />
                            </Box>

                          </Box>
                        )
                      } else if (key === 'bonus_balance') {
                        return (
                          <Box ref={dropdownRefs.bonusBalance}>
                            <MoneyBlock type={"Bonus"} currency={balance.currency} amount={balance.amount}/>
                          </Box>
                        )
                      }
                      return null
                    })
                  ) : (
                    <p>No data available</p>
                  )}
                  <ButtonStyled onClick={() => setShowModal(true)}>{t('Deposit')}</ButtonStyled>
                  {/*<ButtonStyled onClick={handleLogout} variant={"glass"}>{t('Logout')}</ButtonStyled>*/}
                  <div className={styles.specialMarginAuthenticated}>
                    <Notification/>
                    <Box ref={dropdownRefs.language}>
                      <LanguageDropdown
                        showDropdownLanguage={activeDropdown === 'language'}
                        setShowDropdownLanguage={() => handleDropdownToggle('language')}
                      />
                    </Box>
                  </div>
                </>
              ) : (
                <>
                  <ButtonStyled to="/auth/login" variant={'glass'}>Login</ButtonStyled>
                  <div className={styles.registerButtonSpecialMargin}>
                    <ButtonStyled to="/auth/signup">Register</ButtonStyled>
                  </div>
                  <Box ref={dropdownRefs.language}>
                    <LanguageDropdown
                      showDropdownLanguage={activeDropdown === 'language'}
                      setShowDropdownLanguage={() => handleDropdownToggle('language')}
                    />
                  </Box>
                </>
              )}
          </Box>
        </Toolbar>
      </AppBarStyled>
      {/* Используем Modal для отображения компонента PlayerDeposit */}
      {/* <Modal
        show={showModal}
        onClose={handleClosePlayerDepositModal}
        header={
          previousView && (
            <button className={stylesModal.btnPrev} onClick={handleModalBackClick}>
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
              </svg>
            </button>
          )
        }
        body={<PlayerDeposit/>} // Ваш компонент PlayerDeposit
      /> */}
      <ModalPlayerDeposit isShowModal={showModal} onCloseModal={() => setShowModal(false)}/>
    </>
  )
}

export type Language = {
  title: string
  iso_code: string
  id: number
};

export default Navigation
