import styles from './Profile.module.scss'
import {PlayerContainer} from "../../../common/PlayerContainer/PlayerContainer"
import React, {useState} from "react"
import {useSelector} from "react-redux"
import {selectToken} from "../../../redux/selectors/site/loginSelectors"
import {useGetPersonalInfoQuery} from "../../../api/api"
import home_02 from 'src/assets/images/icons/home_02.svg'
import user_circle from 'src/assets/images/icons/user_circle.svg'
import wallet_02 from 'src/assets/images/icons/wallet_02.svg'
import file_06 from 'src/assets/images/icons/file_06.svg'
import {NavigationButtonData} from "../../../common/types"
import {NavigationButtonsGroup} from "../../../common/NavigationButtonsGroup/NavigationButtonsGroup"
import {Promo} from "./Promo/Promo"


export const Profile = () => {
  const token = useSelector(selectToken)
  const {data: getPersonalInfoData} = useGetPersonalInfoQuery({token: token ? token : ''})
  const playerId = getPersonalInfoData?.data?.player_id

  const buttonData: Array<NavigationButtonData> = [
    {id: 1, label: 'Main', img: home_02, imgAlt: 'home_02'},
    {id: 2, label: 'My profile', img: user_circle, imgAlt: 'user_circle'},
    {id: 3, label: 'My wallet', img: wallet_02, imgAlt: 'wallet_02'},
    {id: 4, label: 'History', img: file_06, imgAlt: 'file_06'},
  ]

  const [activeButton, setActiveButton] = useState<number>(1)

  const handleButtonClick = (id: number) => {
    setActiveButton(id) // Set active button
  }

  return (
    <div className={styles.profile}>
      <div className={styles.headerContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.accountPlayer}>
            <div className={styles.account}>Account</div>
            {getPersonalInfoData?.data?.login && playerId &&
                <PlayerContainer loginName={getPersonalInfoData?.data?.login} playerId={playerId}/>}
          </div>
          <div className={styles.navigationContainer}>
            <NavigationButtonsGroup buttonData={buttonData} activeButton={activeButton}
                                    handleButtonClick={handleButtonClick} variant={'profile'}/>
          </div>
        </div>
        <Promo />

      </div>


    </div>
  )
}